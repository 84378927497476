@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #043F99;
}

.bg-image{
    background-image: url('./Assets/homeImmo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 650px;
}


.bg-image-gradient{
    background: #021042b2;
    height: 650px;
}

h1 {
    color: #043F99;
    text-transform: uppercase;
     font-weight: 700;
  }

  h2 {
    color: #021042e8;
    text-transform: uppercase;
     font-weight: 700;
  }

  .bg-partenaire{
    background-image: url(./Assets/partenaire.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 500px;
    max-height:100%;
  }
  .card_sponsor{
    width: 200px;
    height: 200px;
    background-color: white;
  }
  .w-28 {
    width: 9rem !important;
  }
  .h-20 {
    height: 7rem !important;
  }
  .logof{
    height: 40px !important;
  }
  .survol-img .survol-text{
    display: none;
    background: linear-gradient(180deg, #021042CC, #dcded1c5 );
  }

  .survol-img:hover .survol-text{
    display: block;
  }

  .backToTop.show {
    background-color: white;
    height: 50px;
    width: 50px;
    text-align: center;
    position: fixed;
    bottom: 150px;
    right: 20px;
    border-radius: 50%;
    z-index: 99999;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
      opacity: 1;
  }

  .backToTop.hide {
      opacity: 0;
      pointer-events: none; /* Désactiver les événements de pointer lorsque invisible */
  }
  @media screen and (max-width:1024px) {
      .bg-image-gradient{
        background: radial-gradient( circle,#021042CC, #dcded1c5 );
        height: 100%;
        min-height: 650px;
        padding-bottom: 20px;
      }
      .msg_g{
        top: 37% !important;
        left: 5% !important;
      }
      .nji{
        font-size: 2rem !important;
      }
      /* .title1{

      } */
      .title2{
        font-size: 2.5rem !important;
      }
      .box_jour{
        width: 7.5rem !important;
        height: 7rem;
      }
      .box_jour #jour{
        font-size: 2rem !important;
      }
      .box_heure{
        width: 7.5rem !important;
        height: 7rem;
      }
      .box_heure #heure{
        font-size: 2rem !important;
      }
      .box_minute{
        width: 7.5rem !important;
        height: 7rem;
      }
      .box_minute #minute{
        font-size: 2rem !important;
      }
      .box_seconde{
        width: 7.5rem !important;
        height: 7rem;
      }
      .box_seconde #seconde{
        font-size: 2rem !important;
      }
      #jour_label, #heure_label, #minute_label, #seconde_label {
        font-size: 1rem !important;
      }
      .point{
        font-size: 1rem !important;
      }
  }
  @media screen and (max-width:360PX){
    .box_jour{
      width: 4rem !important;
      height: 4.5rem;
    }
    .box_jour #jour{
      font-size: 1.5rem !important;
    }
    .box_heure{
      width: 4.5rem !important;
      height: 4.5rem;
    }
    .box_heure #heure{
      font-size: 1.5rem !important;
    }
    .box_minute{
      width: 4.7rem !important;
      height: 4.5rem;
    }
    .box_minute #minute{
      font-size: 1.5rem !important;
    }
    .box_seconde{
      width: 5rem !important;
      height: 4.5rem;
    }
    .box_seconde #seconde{
      font-size: 1.5rem !important;
    }
    #jour_label, #heure_label, #minute_label, #seconde_label {
      font-size: 0.7rem !important;
    }
    .point{
      font-size: .7rem !important;
    }
  }
  @media (prefers-reduced-motion: no-preference){
    .Animation-option{
      animation: reveal linear both ;
      animation-timeline: view(); 
      animation-range: 25vh 75vh
    }
  }
  
  @keyframes reveal {
    from {
     transform: scale(0.9);
      opacity: 0.5;
    }
   
  }

  .glow {
    color: #fff;
    text-align: center;
    
    
  }
  .loaderr {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #043f99;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    position: relative;
    top: 5px;
    left: 52px;
  }
  .loaderrr {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #043f99;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    position: relative;
    top: 5px;
    left: 2px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  